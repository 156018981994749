import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import theme from '../theme';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { styled } from '@mui/system';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';
import { Typography } from '@mui/material';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
});

// eslint-disable-next-line
export const BlogPostTemplate = ({
	content,
	contentComponent,
	description,
	tags,
	title,
	helmet,
}) => {
	const PostContent = contentComponent || Content;

	return (
		<BnWSection
			className='sectionPost'
			sx={{ boxShadow: 12, color: theme.palette.background.default }}
			mx={8}
			mt={6}
			borderRadius={2}
			elevation={3}
		>
			{helmet || ''}
			<BnWContent px={2}>
				<Grid container spacing={2} sx={{ justifyContent: 'space-between' }} py={4}>
					<Grid item xs={12} sm={12} md={12} className='section'>
						<Typography
							variant='h1'
							className='title is-size-2 has-text-weight-bold is-bold-light'
						>
							{title}
						</Typography>
						<Typography
							component='div'
							dangerouslySetInnerHTML={{ __html: description.html }}
						/>
						<PostContent content={content} />
						{tags && tags.length ? (
							<div style={{ marginTop: `4rem` }}>
								<h4>Tags</h4>
								<ul className='taglist'>
									{tags.map((tag) => (
										<li key={tag + `tag`}>
											<Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
										</li>
									))}
								</ul>
							</div>
						) : null}
					</Grid>
				</Grid>
			</BnWContent>
		</BnWSection>
	);
};

BlogPostTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<BlogPostTemplate
				content={post.html}
				contentComponent={HTMLContent}
				description={post.frontmatter.description}
				helmet={
					<Helmet titleTemplate='%s | Blog'>
						<title>{`${post.frontmatter.title}`}</title>
						<meta
							name='description'
							content={`${post.frontmatter.description.html}`}
						/>
					</Helmet>
				}
				tags={post.frontmatter.tags}
				title={post.frontmatter.title}
			/>
		</Layout>
	);
};

BlogPost.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description
				tags
			}
		}
	}
`;
